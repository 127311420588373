import React from 'react'
import Section from './../Section'
import SectionHeader from './../SectionHeader'
import TeamBios from './../TeamBios'
import './styles.scss'

function TeamBiosSection(props) {
  let people = []
  props.data.names.forEach((name, index) => {
    people.push({
      avatar: props.data.profilePics[index].fluid.src,
      name: props.data.names[index],
      role: props.data.titles[index],
      bio: props.data.bios[index],
      twitterUrl: props.data.twitters[index],
      emailUrl: props.data.emails[index],
      linkedinUrl: props.data.linkedIns[index],
    })
  })
  return (
    <Section
      color={props.color}
      size={props.size}
      style={{ paddingTop: '2rem' }}
    >
      <div className="container">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          centered={true}
          size={3}
        />
        <TeamBios people={people.slice(0, 1)} />
        <br />
        <SectionHeader
          title={'Advisors'}
          subtitle={props.subtitle}
          centered={true}
          size={4}
        />
        <TeamBios people={people.slice(1)} advisors={true} />
      </div>
    </Section>
  )
}

export default TeamBiosSection
