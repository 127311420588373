import React from 'react'
import ContentSection from '../components/ContentSection'
import TeamBiosSection from '../components/TeamBiosSection'
import CtaSection from '../components/CtaSection'
import Navbar from '../components/Navbar'
import Divider from '../components/Divider'
import Footer from '../components/Footer'
import SEO from '../components/seo'
import { graphql } from 'gatsby'
import Announcement from '../components/Announcement'
import { navigate } from '@reach/router'

function AboutPage({ data, location }) {
  return (
    <>
      {/* <Announcement
        color="primary"
        size="danger"
        text="We're hiring! Join us in building the digital health infrastructure!"
        buttons={[
          {
            buttonText: 'Apply',
            buttonOnClick:
              'https://www.notion.so/Akute-Health-Careers-5b3135512c8c477d9b43bb3cae23a509',
          },
          // {
          //   buttonText: 'Learn More',
          //   buttonOnClick:
          //     '/resource-center/covid-19-small-business-relief-programs',
          // },
        ]}
        buttonText="Apply"
        buttonOnClick={() => {
          navigate(
            'https://www.notion.so/Akute-Health-Careers-5b3135512c8c477d9b43bb3cae23a509'
          )
        }}
      /> */}
      <Navbar color="white" spaced={true} data={data.allContentfulNavbar} />
      <SEO title="About Us" />
      {/* <ContentSection
        color="white"
        size="large"
        title="We help you make money"
        subtitle="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorum consequatur numquam aliquam tenetur ad amet inventore hic beatae, quas accusantium perferendis sapiente explicabo, corporis totam!"
      /> */}
      <TeamBiosSection
        color="white"
        size="medium"
        title="Founder"
        subtitle=""
        data={data.allContentfulTeamPage.edges[0].node}
      />
      <CtaSection
        color="primary"
        size="medium"
        title="Want to learn more?"
        subtitle=""
        buttonText="Request a Demo"
        subscribedMessage="Thanks! We will reach out to you shortly."
        formName="about-us"
        // buttonOnClick={() => {
        //   router.push('/pricing')
        // }}
      />
      <Divider color="light" />
      <Footer
        color="light"
        size="medium"
        logo={data.allContentfulNavbar.edges[0].node.brandLogo}
        description={data.allContentfulFooter.edges[0].node.description}
        copyright={
          '© ' +
          data.allContentfulFooter.edges[0].node.companyName +
          ` ${new Date().getFullYear()}`
        }
        socialPages={data.allContentfulFooter.edges[0].node.socialPages}
        socialImages={data.allContentfulFooter.edges[0].node.socialImages}
        driftAppId={data.allContentfulFooter.edges[0].node.driftAppId}
      />
    </>
  )
}

export default AboutPage

export const pageQuery = graphql`
  query {
    allContentfulNavbar {
      edges {
        node {
          brandLogo {
            fixed(width: 250) {
              width
              height
              src
              srcSet
            }
            fluid(maxWidth: 1000) {
              src
            }
          }
          links
        }
      }
    }
    allContentfulTeamPage {
      edges {
        node {
          bios
          titles
          names
          twitters
          linkedIns
          emails
          profilePics {
            fluid(maxWidth: 1000) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
    allContentfulStyles {
      edges {
        node {
          childContentfulStylesButtonJsonNode {
            backgroundColor
            color
          }
        }
      }
    }
    allContentfulFooter {
      edges {
        node {
          companyName
          description
          companyContactEmail
          driftAppId
          socialPages
          socialImages {
            title
            fixed(width: 20) {
              width
              height
              src
              srcSet
            }
          }
        }
      }
    }
  }
`
