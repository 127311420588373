import React from 'react'
import { OutboundLink } from 'gatsby-plugin-google-gtag'
import './styles.scss'

function CardSocialFooter(props) {
  return (
    <footer className="card-footer">
      <link
        rel="stylesheet"
        href="https://use.fontawesome.com/releases/v5.6.3/css/all.css"
        integrity="sha384-UHRtZLI+pbxtHCWp1t77Bi1L4ZtiqrqD80Kn4Z8NTSRyMA2Fd33n5dQ8lWUE00s/"
        crossorigin="anonymous"
      />
      <p className="card-footer-item">
        <nav className="level is-mobile card-nav">
          {props.person.twitterUrl && (
            <div className="level-item">
              <OutboundLink
                href={props.person.twitterUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="link fab fa-twitter"
              />
            </div>
          )}
          {props.person.facebookUrl && (
            <div className="level-item">
              <OutboundLink
                href={props.person.facebookUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="link fab fa-facebook"
              />
            </div>
          )}
          {props.person.linkedinUrl && (
            <div className="level-item">
              <OutboundLink
                href={props.person.linkedinUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="link fab fa-linkedin"
              />
            </div>
          )}
          {props.person.emailUrl && (
            <div className="level-item">
              <OutboundLink
                href={'mailto:' + props.person.emailUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="link fa fa-envelope"
              />
            </div>
          )}
        </nav>
      </p>
    </footer>
  )
}

export default CardSocialFooter
